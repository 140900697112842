import { defineStore } from 'pinia'
import { useHead } from '@unhead/vue'

import UrlHash from '@/tools/url-hash'

import { PAGE_TITLE } from '@/brand'

const DEFAULT_MODE = 'RADAR';

export const useAppStore = defineStore('app', {
  state: () => ({
    booted: false,
    mode: (() => {
      const params = new UrlHash();

      return params.has('m') ? params.get('m') : DEFAULT_MODE;
    })(),
    updating: false,
    radar_drawer_open: false,
    isAnnotating: false
  }),

  actions: {
    ready() {
      this.booted = true
    },

    isReady() {
      return this.booted
    },

    setMode(mode) {
      this.hideAllModes()

      this.mode = mode;

      useHead({
        title: PAGE_TITLE
      })

      this.showMode()

      const params = new UrlHash();
      params.set('m', this.mode);
      params.save();
    },

    hideAllModes() {
      for (const feature of ['radar', 'outlooks', 'satellite']) {
        window.map[feature].hide();
      }
    },

    showMode() {
      window.map[this.mode.toLowerCase()].show();
    },

    startAnnotating() {
      this.isAnnotating = true;
    },

    stopAnnotating() {
      this.isAnnotating = false;
    }
  }
})
