<script setup>
import { vOnClickOutside } from '@vueuse/components'
</script>

<template>
  <SimpleColortable v-if="outlookStore.colortable.length > 0" :colortable="outlookStore.colortable" />

  <div id="outlooks-bar" class="absolute top-16 mt-2 left-3 sm:left-1/2 sm:-translate-x-1/2 w-[100] z-20 rounded-lg flex select-none">
    <div class="bg-white rounded-lg p-1">
      <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 inline p-1" :class="{
        'max-w-28 sm:max-w-full': outlookStore.activeOutlookId !== '0'
      }" v-model="outlookStore.activeOutlookId" @change="onOutlookChange($event)">
        <option value="0" selected>Choose outlook...</option>
        <optgroup label="SPC Convective/Severe Weather">
          <option value="USA/SPC/CONVECTIVE/CATEGORICAL">Categorical</option>
          <option value="USA/SPC/CONVECTIVE/TORNADO">Tornado</option>
          <option value="USA/SPC/CONVECTIVE/WIND">Wind</option>
          <option value="USA/SPC/CONVECTIVE/HAIL">Hail</option>
          <option value="USA/SPC/CONVECTIVE/PROB">Probabilistic</option>
        </optgroup>
        <optgroup label="SPC Thunderstorms">
          <option value="USA/SPC/THUNDERSTORM/THUNDERSTORM">Thunderstorms</option>
        </optgroup>
        <optgroup label="SPC Fire Weather">
          <option value="USA/SPC/FIRE/DRYT">Dry Thunderstorms</option>
          <option value="USA/SPC/FIRE/WINDRH">Wind/Relative Humidity</option>
        </optgroup>
        <!-- <optgroup label="CPC Climate">
          <option value="USA/CPC/CLIMATE/TEMP">Temperature</option>
          <option value="USA/CPC/CLIMATE/PRECIP">Precipitation</option>
        </optgroup> -->
        <optgroup label="WPC Excessive Rainfall">
          <option value="USA/WPC/EXCESSIVE_RAINFALL/RAINFALL">Excessive Rainfall</option>
        </optgroup>
        <optgroup label="WPC Precipitation Forecast">
          <option value="USA/WPC/QPF/DAILY">Daily</option>
          <option value="USA/WPC/QPF/MULTI_DAY">Multi-Day</option>
          <!-- <option value="USA/WPC/QPF/DAY_1_6_HOURS">Day 1 (6 hours)</option>
          <option value="USA/WPC/QPF/DAY_2_6_HOURS">Day 2 (6 hours)</option>
          <option value="USA/WPC/QPF/DAY_3_6_HOURS">Day 3 (6 hours)</option> -->
        </optgroup>
        <optgroup label="WPC WSSI">
          <option value="USA/WPC/WSSI/OVERALL">Overall Impact</option>
          <option value="USA/WPC/WSSI/SNOW_AMOUNT">Snow Amount</option>
          <option value="USA/WPC/WSSI/SNOW_LOAD">Snow Load</option>
          <option value="USA/WPC/WSSI/ICE_ACCUMULATION">Ice Accumulation</option>
          <option value="USA/WPC/WSSI/FLASH_FREEZE">Flash Freeze</option>
          <option value="USA/WPC/WSSI/BLOWING_SNOW">Blowing Snow</option>
          <option value="USA/WPC/WSSI/GROUND_BLIZZARD">Ground Blizzard</option>
        </optgroup>
        <optgroup label="WPC Winter Weather">
          <option value="USA/WPC/WINTER_WEATHER/SNOWFALL_4_INCHES">Snowfall > 4"</option>
          <option value="USA/WPC/WINTER_WEATHER/SNOWFALL_8_INCHES">Snowfall > 8"</option>
          <option value="USA/WPC/WINTER_WEATHER/SNOWFALL_12_INCHES">Snowfall > 12"</option>
          <option value="USA/WPC/WINTER_WEATHER/FREEZING_RAIN">Freezing Rain > 0.25"</option>
        </optgroup>
      </select>
      <template v-if="outlookStore.activeOutlookId !== '0'">
        <a href="#" class="ml-2 inline" @click.prevent="showHelp()"><Icon icon="carbon:help" class="size-5 inline" /></a>
        
        <span class="ml-2">
          <button v-for="(step, index) in outlookStore.steps.slice(0, outlookStore.steps.length <= maxStepOptions ? maxStepOptions : maxStepOptions-1)" class="py-1.5 px-2 sm:px-2.5 rounded-full text-xs relative" :class="{
              'bg-blue-600 hover:bg-blue-700 text-white': step.id === outlookStore.activeStep,
              'hover:text-gray-950 hover:bg-gray-200': step.id !== outlookStore.activeStep,
              'sm:mr-1': index+1 !== (outlookStore.steps.length <= maxStepOptions ? maxStepOptions : maxStepOptions-1)
            }" @click.prevent="onTimestepChange($event, step)"><span class="font-bold" :class="{
              'invisible': step.id === outlookStore.activeStep && outlookStore.isDataLoading
            }" v-text="step.name"></span><Icon v-if="step.id === outlookStore.activeStep && outlookStore.isDataLoading" icon="eos-icons:three-dots-loading" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 inline size-4" /></button>
          
          <template v-if="outlookStore.steps.length > maxStepOptions">
            <span class="relative" v-on-click-outside="onCloseMore">
              <button class="font-bold py-1.5 px-3 ml-2 rounded-full text-xs relative" :class="{
                'bg-blue-600 hover:bg-blue-700 text-white hover:text-white': outlookStore.steps.slice(maxStepOptions-1).find(s => s.id === outlookStore.activeStep) !== undefined,
                'hover:text-gray-950 hover:bg-gray-200': outlookStore.steps.slice(maxStepOptions-1).find(s => s.id === outlookStore.activeStep) === undefined
              }" @click.prevent="onMoreClick"><span class="font-bold" :class="{
              'invisible': outlookStore.steps.slice(maxStepOptions-1).find(s => s.id === outlookStore.activeStep) !== undefined && outlookStore.isDataLoading
            }">More..</span><Icon v-if="outlookStore.steps.slice(maxStepOptions-1).find(s => s.id === outlookStore.activeStep) !== undefined && outlookStore.isDataLoading" icon="eos-icons:three-dots-loading" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 inline size-4" /></button>

              <div v-if="moreDropdownActive" class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow min-w-20 right-0 top-9 absolute">
                <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
                  <li v-for="step in outlookStore.steps.slice(maxStepOptions-1)">
                    <a href="#" class="block px-4 py-2 font-bold text-xs" :class="{
                      'bg-blue-600 hover:bg-blue-700 text-white hover:text-white': step.id === outlookStore.activeStep,
                      'hover:bg-gray-100': step.id !== outlookStore.activeStep
                    }" v-text="step.name.replaceAll(' ', '&nbsp')" @click.prevent="onTimestepChange($event, step)"></a>
                  </li>
                </ul>
              </div>
            </span>
          </template>
        </span>
        
        <a href="#" class="mx-1 inline" @click.prevent="share()"><Icon icon="ion:share-outline" class="size-5 inline" /></a>
      </template>
      
      <!-- <a href="#" class="ml-2 inline" @click.prevent="close()"><Icon icon="clarity:window-close-line" class="size-6 inline" /></a> -->
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue'
import { colord } from 'colord'

import { useOutlookStore } from '@/stores/outlook'

import SimpleColortable from '@/components/SimpleColortable.vue'

import modals from '@/logic/Modals/Helper'

export default {
  name: 'Outlooks',
  components: {
    Icon,
    SimpleColortable
  },
  data() {
    return {
      moreDropdownActive: false,
    };
  },
  async mounted() {
    if(this.outlookStore.activeOutlookId !== '0') {
      await this.onOutlookChange();
    }
  },
  unmounted() {
    this.clear();
  },
  computed: {
    outlookStore() {
      return useOutlookStore()
    },
    maxStepOptions() {
      if(window.innerWidth > 500) return 4;

      return 3;
    }
  },
  methods: {
    async onOutlookChange(e) {
      this.reset()

      this.outlookStore.steps = window.map.outlooks.setSteps(this.outlookStore.activeOutlookId);

      if(this.outlookStore.steps.length === 0) {
        return this.clear();
      }

      // Load data...
      await window.map.outlooks.renderOutlook(this.outlookStore.activeOutlookId, this.outlookStore.activeStep);
    },
    async onTimestepChange(e, step) {
      this.reset();

      this.outlookStore.activeStep = step.id

      // Load data...
      await window.map.outlooks.renderOutlook(this.outlookStore.activeOutlookId, this.outlookStore.activeStep);
    },
    reset() {
      this.onCloseMore();
      window.map.popups.clear();
    },
    clear() {
      this.reset();
      
      this.outlookStore.steps = [];
      this.outlookStore.colortable = [];
      window.map.outlooks.clear();
    },
    showHelp() {
      window.map.outlooks.openOutlookHelpModal(this.outlookStore.activeOutlookId);
    },
    share() {
      modals.share();
    },
    close() {
      this.clear();
    },
    onMoreClick() {
      this.moreDropdownActive = !this.moreDropdownActive;
    },
    onCloseMore() {
      this.moreDropdownActive = false;
    }
  }
}
</script>

<style scoped>

</style>
