<template>
	<button @click="toggle()" type="button" class="text-white bg-slate-400 font-medium rounded-lg text-sm px-2.5 py-2 sm:py-3 text-center me-1 mb-1 text-xs font-black">
    <span class="font-bold mr-0.5">Tilt</span>
    <span class="inline-flex items-center justify-center h-4 ms-1 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full px-1">{{ activeTilt }}&deg;</span>
    <Icon :icon="isOpen ? 'gravity-ui:chevron-up-wide' : 'gravity-ui:chevron-down-wide'" class="inline -mt-[1px] ml-1 font-bold size-5" v-show="tilts.length > 1" />
  </button>
  <Transition name="slide-fade">
    <div v-if="isOpen" class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 right-0 absolute">
      <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
        <li v-for="(tilt, i) in tilts">
          <a href="#" class="block px-4 py-2 hover:bg-gray-100" v-text="getTilt(tilt, i) + '&deg;'" @click.prevent="onTiltClick($event, tilt)"></a>
        </li>
      </ul>
    </div>
  </Transition>
</template>

<script>
import { Icon } from '@iconify/vue'

import { useRadarTowersStore } from '@/stores/radar_towers'

export default {
  name: 'RadarTilts',
  props: ['productGroup'],
  components: {
    Icon
  },
  setup() {
    const radarTowersStore = useRadarTowersStore();

    return {
      radarTowersStore
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    activeTilt() {
      if (! this.productGroup) {
        return '0.0';
      }

      const activeProductIdx = this.productGroup.tilts.findIndex(item => item.product === this.radarTowersStore.activeProductCode);

      if (activeProductIdx === -1) {
        return '0.0';
      }

      const activeProduct = this.productGroup.tilts[activeProductIdx];

      return this.getTilt(activeProduct, activeProductIdx)      
    },
    tilts() {
      if (! this.productGroup) {
        return [];
      }

      return this.productGroup.tilts
    }
  },
  methods: {
    open() {
      if (this.tilts.length <= 1) {
        return;
      }

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      if (!this.isOpen) {
        this.open();
      } else {
        this.close();
      }
    },
    onTiltClick(e, tilt) {
      this.close();

      const feature = window.map.radar.changeRadarProduct(this.radarTowersStore.activeTowerId, tilt.product)
    },
    getTilt(tilt, idx) {
      if(typeof tilt.elevation === 'string') {
        return tilt.elevation;
      }

      const props = this.radarTowersStore.activeTower.properties;

      if(props.elevations === undefined) {
        return '0.0';
      }

      if(props.elevations.length < idx+1) {
        return '0.0';
      }

      return props.elevations[idx];
    }
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  display: none;
}
</style>
