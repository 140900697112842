<template>
  <p v-html="feature.properties.discussion.replace(/(?:\r\n|\r|\n)/g, '<br>')"></p>

  <hr>
  <p class="text-sm text-slate-600">The information is provided to us in realtime from the National Weather Service (NWS), specifically from the National Hurricane Center (NHC). Last update at: {{ lastUpdatedAt }}</p>
</template>

<script>
import moment from 'moment'

export default {
  name: 'StormProductHelp',
  props: ['feature'],
  emits: ['close'],
  computed: {
    lastUpdatedAt() {
      return moment.utc(this.feature.properties.last_update_at).local().format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
