<script setup lang="ts">
import { ref, computed } from 'vue'
import { Icon } from '@iconify/vue'

import { useDrawingSettingsStore } from '@/stores/settings/drawing'
import { storeToRefs } from 'pinia'

const drawingStore = useDrawingSettingsStore()

const props = defineProps<{
  modelValue: number
  min?: number
  max?: number
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void
}>()

const sliderRef = ref<HTMLDivElement | null>(null)
const isDragging = ref(false)

const minValue = computed(() => props.min ?? 1)
const maxValue = computed(() => props.max ?? 50)

const percentage = computed(() => {
  return ((props.modelValue - minValue.value) / (maxValue.value - minValue.value)) * 100
})

const updateValue = (clientX: number) => {
  const slider = sliderRef.value
  if (!slider) return

  const rect = slider.getBoundingClientRect()
  const x = Math.max(0, Math.min(clientX - rect.left, rect.width))
  const percentage = x / rect.width
  const value = Math.round(
    percentage * (maxValue.value - minValue.value) + minValue.value
  )
  emit('update:modelValue', value)
}

const handleStart = (event: MouseEvent | TouchEvent) => {
  event.preventDefault()
  event.stopPropagation()

  isDragging.value = true
  const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX
  updateValue(clientX)

  if ('touches' in event) {
    document.addEventListener('touchmove', handleMove, { passive: false })
    document.addEventListener('touchend', handleEnd)
    document.addEventListener('touchcancel', handleEnd)
  } else {
    document.addEventListener('mousemove', handleMove)
    document.addEventListener('mouseup', handleEnd)
  }
}

const handleMove = (event: MouseEvent | TouchEvent) => {
  if (!isDragging.value) return
  event.preventDefault()
  event.stopPropagation()
  const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX
  updateValue(clientX)
}

const handleEnd = () => {
  isDragging.value = false
  document.removeEventListener('mousemove', handleMove)
  document.removeEventListener('mouseup', handleEnd)
  document.removeEventListener('touchmove', handleMove)
  document.removeEventListener('touchend', handleEnd)
  document.removeEventListener('touchcancel', handleEnd)
}
</script>

<template>
  <div class="flex flex-col gap-4 w-full p-2 touch-none">
    <div class="flex items-center gap-2">
      <Icon icon="mdi:brush" class="w-5 h-5 text-gray-600" />
      <span class="text-sm font-medium text-gray-700">Size: {{ modelValue }}px</span>
    </div>

    <div class="flex items-center gap-4">
      <div class="w-12 h-12 flex items-center justify-center">
        <div
          class="slider-preview border border-gray-200 rounded-full flex-shrink-0"
          :style="{
            width: `${modelValue}px`,
            height: `${modelValue}px`,
            minWidth: '4px',
            minHeight: '4px',
            maxWidth: '100%',
            maxHeight: '100%',
            background: drawingStore.getRGBALineColor
          }"
        ></div>
      </div>

      <div
        ref="sliderRef"
        class="slider-track relative h-11 flex-grow flex items-center cursor-pointer touch-none"
        @mousedown="handleStart"
        @touchstart="handleStart"
      >
        <div
          class="slider-fill absolute h-2 rounded pointer-events-none"
          :style="{ width: `${percentage}%` }"
        ></div>
        <div
          class="slider-thumb absolute w-5 h-5 bg-blue-600 border-3 border-white rounded-full -translate-x-1/2 shadow-md pointer-events-none transition-transform duration-100"
          :style="{ left: `${percentage}%` }"
          :class="{ 'scale-110': isDragging }"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slider-track::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 8px;
  @apply bg-gray-200 rounded;
}

@media (max-width: 768px) {
  .slider-track {
    height: 36px;
  }
}

@media (hover: hover) {
  .slider-track:hover .slider-thumb {
    @apply scale-110;
  }
}
</style>