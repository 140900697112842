import '../node_modules/mapbox-gl/dist/mapbox-gl.css'
import '../node_modules/nprogress/nprogress.css'
import 'vue-final-modal/style.css'
import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { createVfm } from 'vue-final-modal'
import VueSocialSharing from 'vue-social-sharing'
import { createPlausible } from 'v-plausible/vue'
import { createHead } from '@unhead/vue'

import UserbackPlugin from '@userback/vue';

import App from './App.vue'
import router from './router'

import RollbarPlugin from './rollbar'

if (import.meta.env.DEV) {
  console.log('env', import.meta.env)
}

const plausible = createPlausible({
  init: {
    domain: 'web.weatherwise.app',
    apiHost: 'https://plausible.io',
    trackLocalhost: false,
    hashMode: false
  },
  settings: {
    enableAutoOutboundTracking: true,
    enableAutoPageviews: true,
  },
  partytown: false,
})

const app = createApp(App)

const pinia = createPinia()
pinia.use(
  createPersistedState({
    key: (id) => `ww:store:${id}`
  })
)

app.use(pinia)
app.use(router)
app.use(createVfm())
app.use(RollbarPlugin)

app.use(VueSocialSharing)
app.use(plausible)

const head = createHead()
app.use(head)

// Only show in production and the web browser (not the app)
app.use(UserbackPlugin, {
  token: 'A-zwpuyIZmAr2ke0wULbwde3lA9'
});

app.mount('#app')
