export const stopPropagation = (fn) => {
	return (...args) => {
		const e = args[0];

		if(e.ww_stopPropagation_ === true) return;

		e.ww_stopPropagation_ = true;

		return fn.apply(this, args);
	};
};

export const applyIsInteracting = (map) => {
	// Setup initial state
	map.isUserInteracting = false;

	// List of events that indicate user starts interacting
	const interactionStartEvents = ['mousedown', 'dragstart', 'zoomstart', 'rotatestart', 'pitchstart'];

	// List of events that indicate user stops interacting
	const interactionEndEvents = ['mouseup', 'dragend', 'zoomend', 'rotateend', 'pitchend'];

	// Set up listeners for start events
	interactionStartEvents.forEach(event => {
		map.on(event, () => {
			map.isUserInteracting = true;
		});
	});

	// Set up listeners for end events
	interactionEndEvents.forEach(event => {
		map.on(event, () => {
			map.isUserInteracting = false;
		});
	});
};