import { toRaw } from 'vue'
import { defineStore } from 'pinia'
import { mande } from 'mande'
import moment from 'moment'

import { removeExpired, automaticallyRemoveExpired } from './helpers'
import api from '../logic/Api'

const CHECK_FOR_EXPIRED_INTERVAL = 61 * 1000

let init = false

export const useMesoscaleDiscussionsStore = defineStore('mesoscale_discussions', {
  state: () => ({
    geojson: {
      type: 'FeatureCollection',
      features: []
    },
    last_update_at: null
  }),

  persist: {
    afterRestore: (ctx) => {
      // filter out expired mesoscale discussions
      ctx.store.geojson.features = removeExpired(toRaw(ctx.store.geojson.features))
    }
  },

  actions: {
    init() {
      if (init) return
      init = true

      automaticallyRemoveExpired(this, CHECK_FOR_EXPIRED_INTERVAL);
    },

    async load() {
      try {
        const geojson = await api.instance().get(`/mesoscale-discussions/USA.geojson?_=${(new Date()).getTime()}`)

        geojson.features = removeExpired(geojson.features)

        this.geojson = geojson
        this.last_update_at = moment.utc().toISOString()
      } catch (error) {
        console.log(error)
        return error
      }
    },

    push(feature) {
      // Check that the warning is not expired
      const now = moment.utc()
      const expiresAt = moment.utc(feature.properties.expires_at)

      if(expiresAt.isBefore(now)) return;

      this.geojson.features.push(feature)
    },

    filter(product) {
      return this.geojson.features.filter((f) => {
        return f.properties.product === product
      })
    },

    getFeatureById(id, raw = false) {
      const manip = raw ? toRaw : (f) => { return f; };
      return manip(this.geojson.features.find(f => f.properties.id === id));
    }
  }
})
