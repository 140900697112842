<template>
  <div>
    <p>We wish to say a special thank you to everyone that provides us with the data that powers WeatherWise ❤️</p>
  </div>
  <div>
    <h4 class="font-bold">Canada</h4>
    <p>Data Source: <a href="https://www.canada.ca/en/environment-climate-change.html" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">Environment and Climate Change Canada (ECCC)</a></p>
  </div>
  <div>
    <h4 class="font-bold">Colombia</h4>
    <p>Data Source: <a href="https://www.ideam.gov.co/" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">IDEAM</a></p>
  </div>
  <div>
    <h4 class="font-bold">Finland</h4>
    <p>Data Source: <a href="https://en.ilmatieteenlaitos.fi/" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">Finnish Meteorological Institute</a></p>
  </div>
  <div>
    <h4 class="font-bold">Poland</h4>
    <p>Data Source: <a href="http://www.imgw.pl/" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">Institute of Meteorology and Water Management</a></p>
  </div>
  <div>
    <h4 class="font-bold">United States of America</h4>
    <p>Data Source: <a href="https://www.noaa.gov/" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">NOAA/NWS</a>, <a href="https://www.nasa.gov/" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">NASA</a></p>
  </div>
</template>