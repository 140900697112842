import { toRaw } from 'vue'
import { defineStore } from 'pinia'
import moment from 'moment'

import { removeNonActive, automaticallyRemoveExpired } from './helpers'
import api from '../logic/Api'

const CHECK_FOR_EXPIRED_INTERVAL = 60 * 1000

let init = false

const geometryCache = {};

export const useWarningsStore = defineStore('warnings', {
  state: () => ({
    geojson: {
      type: 'FeatureCollection',
      features: []
    },
    last_update_at: null
  }),

  // TODO
  // Add back caching of warnings for offline use
  // Look at using zipson
  // https://prazdevs.github.io/pinia-plugin-persistedstate/guide/config.html#serializer
  // persist: {
  //   // serializer: {
  //   //   serialize: (state) => {
  //   //     // TODO
  //   //     // Improve this...
  //   //     // This is a bit of hack so that we only store warnings and statements locally
  //   //     // Ie we don't store watches that have massive geometry
  //   //     const c = structuredClone(toRaw(state))
  //   //     c.geojson.features = c.geojson.features.map(f => {
  //   //       if(f.properties.significance === 'A') f.geometry = null;
  //   //       return f;
  //   //     });

  //   //     return JSON.stringify(c)
  //   //   },
  //   //   // I believe once a serializer is defined, you must define both 'serialize' and 'deserialize'
  //   //   deserialize: (p) => {
  //   //     return JSON.parse(p)
  //   //   }
  //   // },
  //   afterRestore: (ctx) => {
  //     // filter out expired warnings
  //     ctx.store.geojson.features = removeExpired(toRaw(ctx.store.geojson.features))
  //   }
  // },

  getters: {
    emergencyExists: (state) => state.geojson.features.find(f => f.properties.emergency) !== undefined,
  },

  actions: {
    init() {
      if (init) return
      init = true

      automaticallyRemoveExpired(this, CHECK_FOR_EXPIRED_INTERVAL);

      // Let's cleanup the geometry cache automatically
      setInterval(() => {
        const allWarningsIds = this.geojson.features.map(f => f.properties.id);

        for (const key in geometryCache) {
          if(! allWarningsIds.includes(key)) {
            delete geometryCache[key];
          }
        }
      }, CHECK_FOR_EXPIRED_INTERVAL);
    },

    async load() {
      try {
        const geojson = await api.instance().get(`/warnings/USA.geojson?_=${(new Date()).getTime()}`)

        const features = removeNonActive(geojson.features);

        this.geojson.features = features;

        // this.geojson = geojson
        this.last_update_at = moment.utc().toISOString()

        // Now handle the non-GeoJSON features
        const nonGeoJsonFeatures = features.filter(f => f.geometry === null);

        // console.log('add non-geojson features', nonGeoJsonFeatures.length)

        if(nonGeoJsonFeatures.length > 0) {
          await Promise.allSettled(nonGeoJsonFeatures.map(feature => this.processNonGeoJsonFeature(feature)));
        }
      } catch (error) {
        console.log('Failed to load from warnings store', error)
        return error
      }
    },

    async fetchGeometry(feature) {
      const warningId = feature.properties.id;

      if(feature.properties.geometry === undefined || feature.properties.geometry === null || typeof feature.properties.geometry !== 'object')
        return console.log('Warning feature is missing extra geometry', warningId)

      const extraGeometry = feature.properties.geometry;

      if(extraGeometry.type !== 'REMOTE') return console.log("Warning feature uses unsupported geometry", warningId)

      let geometry = geometryCache[warningId];
      if(geometryCache[warningId] === undefined) {
        try {
          geometry = await api.instance().get(`/warnings/archive/${warningId}-geometry.geojson`);

          geometryCache[warningId] = geometry;
        }
        catch (e) {
          console.error(`Failed to fetch geometry for warning: ${warningId}`, e);

          return null;
        }
      }

      return geometry.geometry;
    },

    async processNonGeoJsonFeature(feature) {
      const warningId = feature.properties.id;

      const geometry = await this.fetchGeometry(feature);

      if(geometry === null) return;

      const idx = this.geojson.features.findIndex(f => f.properties.id === warningId);
      if(idx >= 0) {
        this.geojson.features[idx].geometry = geometry;
      }
    },

    async push(feature) {
      // Check that the warning is not expired
      const now = moment.utc()
      const expiresAt = moment.utc(feature.properties.expires_at)

      if(expiresAt.isBefore(now)) return;

      // Check that we don't already have a warning with the same ID
      // If there is, then we'll update it
      const idx = this.geojson.features.findIndex(f => {
        return f.properties.id === feature.properties.id || f.properties.common_id === feature.properties.common_id;
      });
      if(idx >= 0) {
        this.geojson.features[idx] = feature
      }
      else {
        this.geojson.features.push(feature)
      }

      if(feature.geometry === null) {
        await this.processNonGeoJsonFeature(feature);
      }
    },

    delete(id) {
      this.geojson.features = this.geojson.features.filter(f => f.properties.id !== id);

      if(geometryCache[id] !== undefined) {
        delete geometryCache[id];
      }
    },

    filter(significance, product) {
      return this.geojson.features.filter((f) => {
        const matchSig = f.properties.significance === significance

        if (product === undefined) return matchSig

        return matchSig && f.properties.product === product
      })
    },

    getFeatureById(id, raw = false) {
      const manip = raw ? toRaw : (f) => { return f; };
      return manip(this.geojson.features.find(f => f.properties.id === id));
    }
  }
})
