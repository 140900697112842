class App {
  isNativeApp() {
    return window.WeatherWiseBridge !== undefined;
  }

  hash() {
    return __COMMIT_HASH__;
  }
}

export default new App()
