<script setup lang="ts">
import { useTemplateRef, onMounted } from 'vue'
import SettingsSection from './MapSettings/SettingsSection.vue';
import RegionSettings from './MapSettings/RegionSettings.vue';
import { useMapSettingsStore } from '@/stores/settings/map';

const mapSettingsStore = useMapSettingsStore();

mapSettingsStore.$subscribe((mutation, state) => {
  mapSettingsStore.applyToMapStyle();
});

const onResetClick = () => {
  mapSettingsStore.reset();
};

// the first argument must match the ref value in the template
const input = useTemplateRef('scrollable-content')

onMounted(() => {
  input.value.scrollTop = 1;

  input.value.onscroll = (event) => {
    if(input) {
      if(input.value) {
        if(input.value.scrollTop < 1) {
          input.value.scrollTop = 1;
        }
      }
    }
  };
})
</script>

<template>
  <div class="scrollable-content" ref="scrollable-content">
    <div class="space-y-6 px-3 sm:px-6">
      <RegionSettings
        title="Country Settings"
        kind="country"
        :settings="mapSettingsStore.country"
        @update:settings="settings => mapSettingsStore.updateRegionSettings('country', settings)"
      />
      
      <RegionSettings
        title="State Settings"
        kind="state"
        :settings="mapSettingsStore.state"
        @update:settings="settings => mapSettingsStore.updateRegionSettings('state', settings)"
      />
      
      <RegionSettings
        title="County Settings"
        kind="county"
        :settings="mapSettingsStore.county"
        @update:settings="settings => mapSettingsStore.updateRegionSettings('county', settings)"
      />
      
      <RegionSettings
        title="Settlement Settings"
        kind="settlement"
        :settings="mapSettingsStore.settlement"
        @update:settings="settings => mapSettingsStore.updateRegionSettings('settlement', settings)"
      />

      <div v-if="mapSettingsStore.anyChanged">
        <button class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-2.5 py-2 pt-1.5 w-full mt-10" @click="onResetClick">Reset</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.simple-modal-bottom-50 .scrollable-content {
  max-height: calc(50vh - 120px);
  overflow-x: hidden;
  overflow-y: auto;
}
</style>