import { defineStore } from 'pinia'

export const useRadarSettingsStore = defineStore('settings/radar', {
  state: () => ({
    storm_tracks: 1,
    lightning: 1,
    melting_layer: 0,
    max_scans: 15
  }),

  persist: true
})
