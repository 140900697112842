<script setup>
import { onUnmounted, ref } from 'vue';
import DotNavigation from './DotNavigation.vue'

defineEmits(['update:step'])

const videoRef = ref(null);

onUnmounted(() => {
  if (videoRef.value) {
    videoRef.value.pause();
    videoRef.value.src = '';
  }
});
</script>

<template>
  <div class="step">
    <div class="video-container">
      <video
        ref="videoRef"
        src="../../assets/onboarding/locate.mp4"
        poster="../../assets/onboarding/locate.png"
        class="video"
        autoplay
        muted
        loop
        playsinline
        preload="metadata"
      ></video>
    </div>
    <h1 class="text-center mt-10">Locate your Nearest<br>Weather Radar Tower</h1>
    <p class="text-center font-medium mt-10">
        Let's begin. Click the button below to locate your nearest radar tower.
    </p>
    <DotNavigation :current-step="5" @update:step="$emit('update:step', $event)" />
  </div>
</template>

<style scoped>
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.illustration {
  width: 240px;
  height: 240px;
  margin-bottom: 2rem;
}

h1 {
  color: #1e293b;
  font-size: 1.875rem;
  font-weight: 700;
}

p {
  color: #64748b;
}

.video-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background-color: black;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}
</style>