import { toRaw } from 'vue'
import { defineStore } from 'pinia'
import moment from 'moment'

import UrlHash from '@/tools/url-hash'

import radarTowers from '@/data/radar_towers.js'
import { polarProducts, products, productGroups } from '@/data/usa_radar_products'

export const DEFAULT_RADAR_REF_PRODUCT_CODES = [
// NEXRAD
'NXB',
'NYB',
'NZB',
'N0B',
'NAB',
'N1B',
'NBB',
'N2B',
'N3B',
// T towers
'TZL',
'TZ0',
'TZ1',
'TZ2',
// Other
'REF0',
'REF1',
'REF2',
'REF3',
'REF4',
'REF5',
'REF6',
'REF7',
'REF8',
'REF9',
];

export const DEFAULT_RADAR_VEL_PRODUCT_CODES = [
// NEXRAD
'NXG',
'NYG',
'NZG',
'N0G',
'NAG',
'N1G',
'NBU',
'N2U',
'N3U',
// T towers
'TV0',
'TV1',
'TV2',
// Other
'VEL0',
'VEL1',
'VEL2',
'VEL3',
'VEL4',
'VEL5',
'VEL6',
'VEL7',
'VEL8',
'VEL9',
];

const DEFAULT_TOWER_ID = '0';
const DEFAULT_PRODUCT_CODE = '';

// TODO
// This store could do with some refactor, but it is working...

export const useRadarTowersStore = defineStore('radar_towers', {
  state: () => ({
    geojson: {
      type: 'FeatureCollection',
      features: []
    },
    activeTowerId: DEFAULT_TOWER_ID,
    activeProductCode: DEFAULT_PRODUCT_CODE,
    activeColorMap: null,
    availableProducts: [],
    availablePolarProducts: [],
    scanDatetime: null,
    scanVcp: null,
    isPlaying: false,
    hasBufferedScans: false,
    inspectorActive: false
  }),

  persist: {
    // Store some properties related to radar in the url
    storage: {
      getItem: (key) => {
        const params = new UrlHash();

        const towerId = params.has('rt') ? params.get('rt') : DEFAULT_TOWER_ID;
        const productCode = params.has('rp') ? params.get('rp') : DEFAULT_PRODUCT_CODE;

        // Check that the tower exists
        const feature = radarTowers.features.find(f => f.properties.id === towerId);
        if(feature === undefined) return;

        // Check that the tower has the product available
        if(! feature.properties.products.includes(productCode)) return;

        const store = useRadarTowersStore();

        store.initTowerAndProduct(towerId, productCode)
      },
      setItem: (key, value) => {
        const store = useRadarTowersStore();

        const params = new UrlHash();
        if(store.activeTowerId === DEFAULT_TOWER_ID) {
          params.delete('rt')
          params.delete('rp')
        }
        else {
          params.set('rt', store.activeTowerId)
          params.set('rp', store.activeProductCode)
        }
        params.save();
      }
    }
  },

  getters: {
    anyActive: (state) => state.geojson.features.length > 0,
    activeTower: (state) => state.geojson.features[0],
    scanLocalTime: (state) => moment.utc(toRaw(state.scanDatetime)).local().format('HH:mm:ss'),
    scanLocalDatetime: (state) => moment.utc(toRaw(state.scanDatetime)).local().format('YYYY-MM-DD HH:mm:ss'),
    scanIsOld: (state) => moment.utc(toRaw(state.scanDatetime)).isBefore(moment().utc().subtract(1, 'hours')),
    scanIsNew: (state) => moment.utc(toRaw(state.scanDatetime)).isAfter(moment.utc().subtract(10, 'minutes')),
    productName: (state) => products[state.activeProductCode] ?? null,
    towerProducts: (state) => {
      return productGroups.map(item => ({
        ...item,
        tilts: item.tilts.filter(tilt => state.availablePolarProducts.includes(tilt.product))
      }));
    },
    activeProductGroup(state) {
      const active = this.towerProducts.find(item => {
        return item.tilts.some(subItem => subItem.product === state.activeProductCode)
      })

      return active ?? null
    },
    activeProductGroupUnit(state) {
      if (! state.activeProductGroup?.unit) {
        return '';
      }

      return state.activeProductGroup.unit
    },
    activeColorTable(state) {
      return state.activeColorMap?.colors
    }
  },

  actions: {
    initTowerAndProduct(tower, product) {
      this.$patch({
        activeTowerId: tower,
        activeProductCode: product
      })
    },

    setActiveTower(feature) {
      const p = {
        geojson: {
          type: 'FeatureCollection',
          features: [feature]
        },
        activeTowerId: feature.properties.id
      }

      if(! feature.properties.products.includes(this.activeProductCode)) {
        for(const code of DEFAULT_RADAR_REF_PRODUCT_CODES) {

          if(feature.properties.products.includes(code)) {
            p.activeProductCode = code;
            break;
          }
        }
      }

      this.$patch(p);
    },

    setAvailableProducts(products) {
      const map = {};
      polarProducts.forEach(p => map[p] = 1)

      this.$patch({
        availableProducts: products,
        availablePolarProducts: products.filter(p => map[p] !== undefined)
      })
    },

    setActiveProductCode(activeProductCode) {
      this.activeProductCode = activeProductCode;
    },

    setScanDatetime(dt) {
      this.scanDatetime = dt;
    },

    clearScanDatetime() {
      this.setScanDatetime(null)
    },

    setScanVcp(vcp) {
      this.scanVcp = vcp;
    },

    clearScanVcp() {
      this.setScanVcp(null)
    },

    setColorMap(map) {
      this.activeColorMap = map;
    },

    clearColorMap() {
      this.setColorMap();
    },

    clear() {
      this.geojson.features = [];
      this.activeTowerId = DEFAULT_TOWER_ID;
      // Don't reset active product code...
      this.activeColortable = [];
      this.availableProducts = [];
      this.availablePolarProducts = [];
      this.scanDatetime = null;
    }
  }
})
