import api from '@/logic/Api'

export default class BaseRadar {
  constructor(map) {
    this.map = map
  }

  async loadLatestFile(id, product) {
    const list = await this.fetchLatestList(id, product)

    // console.log(list)

    if (list.length == 0) {
      // TODO
      return
    }

    const latestScanFilename = list[list.length - 1]

    // const latestScanFilename = 'latest.json';

    return this.fetchJson(id, product, latestScanFilename)
  }

  async fetchLatestList(id, product, limit = -1) {
    const list = await (
      await api.instance().get(`/radar/processed/${id}/${product}/dir.list`, { responseAs: 'response' })
    ).text()

    const cleanList = list.split('\n').filter((l) => l.length > 0);

    if(limit === -1) return cleanList;

    return cleanList.slice(Math.max(cleanList.length - limit, 0))
  }

  fetchJson(id, product, filename) {
    return api.instance().get(`/radar/processed/${id}/${product}/${filename}`)
  }

  async fetchJsonByUrl(url) {
    const response = await fetch(url);
    if (! response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    return response.json();
  }
}
